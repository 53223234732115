/* This is a sin */
*:not(svg) {
  transition: all 260ms linear;
}

/**
 Content section
 */
.content {
  padding: 15px;
  display: flex;
}

.column {
  flex-direction: column;
}


.centered {
  align-items: center;
  justify-content: center;
}

.responsiv-uploader-fileupload.style-image-single {
  height: 100%;
}

.responsiv-uploader-fileupload.style-image-single .upload-button {
  position: absolute !important;
  top: 0; right: 0; bottom: 0; left: 0;
}

.responsiv-uploader-fileupload.style-image-single .upload-object .icon-container.image img {    
  width: auto !important;
}

.upload-files-container,
.responsiv-uploader-fileupload.style-image-single .upload-object,
.responsiv-uploader-fileupload.style-image-single .upload-object .icon-container { 
  height: 100%;
}

.responsiv-uploader-fileupload.style-image-single .upload-object .icon-container { 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.responsiv-uploader-fileupload.style-image-single .upload-object { 
  width: 100%;
}

.b__items.b__items--grid .b__items_item .item-thumbnail .actions {
  height: 100%;
  position: absolute;
  width: 80%;
  top: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.b__items.b__items--grid .b__items_item .item-thumbnail .actions .edit {
  height: 48px;
  width: 100%;
  margin: 6px 0;
  font-size: 1rem;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.b__items.b__items--grid .b__items_item .item-thumbnail .actions .edit:hover {
  opacity: 1;
}

.user-name a {
  text-decoration : none;
  color: #333;
  transition: font-weight 250ms ease-in;
}
.user-name a:hover {
  font-weight: bolder;
}

.b__items-fast-filter ul li {
  cursor: pointer;
}

.b__items-fast-filter ul li:hover {
    border-bottom: solid 2px #3399ff;
}

.b__feed-item.b__feed-item--comment .item-university {
	position: absolute;
	height: 40px;
	width: 100px;
	transform: rotate(45deg);
	right: -52px;
	top: -5px;
	z-index: 10;
}

.b__feed-item .item-date {
	font-size: 12px;
	font-weight: 400;
	color: #bbbbbb;
	display: inline-block;
	width: 100%;
	vertical-align: middle;
}

.hidden {
  display: none !important;
}


.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}
a.label:hover,
a.label:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #777777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #3097d1;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #2579a9;
}
.label-success {
  background-color: #4eb76e;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #3d9558;
}
.label-info {
  background-color: #8eb4cb;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #6b9dbb;
}
.label-warning {
  background-color: #cbb956;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #b6a338;
}
.label-danger {
  background-color: #bf5329;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #954120;
}

.loading {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/* .b__home-map #home-map .marker {
  width: 80px !important;
  height: 80px !important;
  border-color: #fff;
  position: relative;
  float: left;
	border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border-bottom-right-radius: 240px 110px;
  border-bottom-left-radius: 240px 110px;
  border-width: 6px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0, 0.35);
}

.b__home-map #home-map .marker-thumb img {
  width: 80px;
  height: auto;
  border-radius: 50%;
}

.b__home-map #home-map .marker:first-child {
  margin-left: 0;
}  
  
.b__home-map #home-map .marker-thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
} */

/* .b__home-map #home-map .marker:before, .b__home-map #home-map .marker:after{
  content:'';
  position:absolute;
  top: 0px;
  width: 240px;
  height: 150px;
  border: 1px solid #fff;
}
.b__home-map #home-map .marker:before{
  left:0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 240px 110px;
}
.b__home-map #home-map .marker:after{
  right:0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 240px 110px;
} */
